<template>
  <v-app>
    <v-app-bar
      app
      color="#f9f8f8"
      class="acp-appbar" data-html2canvas-ignore="true"> 
      <AcpHeader/>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
    <v-footer>
      <v-container>
        <span class="acp-footer" v-html="$t('app.footerText')">
        </span>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import AcpHeader from './components/AcpHeader';

export default {
  name: 'App',

  components: {
   AcpHeader
},

  data: () => ({
  }),
};
</script>
