<template>
    <v-container class="siteheader">
        <v-row justify="center" class="pt-3">
            <v-col cols="4" align-self="center">
                <!-- <div class="search-box"> -->
                    <!-- <div class="search-icon"></div> -->
                    <!-- <form role="search" method="get" class="search-form" action="https://pancanal.com/">
                        <button type="submit" class="search-submit"><span
                            class="screen-reader-text">Búsqueda</span></button>
                        <label>
                            <input type="search" class="search-field" placeholder="Búsqueda" value="" name="s">
                        </label>
                    </form> -->
                <!-- </div> -->
                <a href="https://pancanal.com/tarifas-maritimas" rel="home">
                    <v-img src="../assets/images/house.svg" contain width="50px"></v-img>
                </a>
            </v-col>
            <v-col cols="4" align-self="center">
                <v-row justify="center" class="nav_logo">
                    <a href="https://pancanal.com/tarifas-maritimas" rel="home">
                        <img alt="Autoridad del Canal de Panamá" class="img-fluid"
                        src="../assets/images/p-logo.png">
                    </a>
                </v-row>
            </v-col>
            <v-col cols="4" align-self="center">
                <v-row justify="end" margin no-gutters>
                    <div class="lan_switch">
                        <section id="icl_lang_sel_widget-3" class="widget widget_icl_lang_sel_widget">
                        <div class="lang_sel_list_horizontal wpml-ls-sidebars-language-widget wpml-ls wpml-ls-legacy-list-horizontal"
                            id="lang_sel_list">
                            <ul>
                                <li
                                    class="icl-en wpml-ls-slot-language-widget wpml-ls-item wpml-ls-item-en wpml-ls-first-item wpml-ls-item-legacy-list-horizontal">
                                    <a href="/en" class="wpml-ls-link">
                                        <span class="wpml-ls-native icl_lang_sel_native" lang="en">EN</span></a>
                                </li>
                                <li
                                    class="icl-es wpml-ls-slot-language-widget wpml-ls-item wpml-ls-item-es wpml-ls-current-language wpml-ls-last-item wpml-ls-item-legacy-list-horizontal">
                                    <a href="/es" class="wpml-ls-link">
                                        <span class="wpml-ls-native icl_lang_sel_native">ES</span></a>
                                </li>
                            </ul>
                        </div>
                        </section>
                    </div>
                    <!-- <div id="res_menu_b" onclick="mOpen()"><span></span></div> -->
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
  name: 'AcpHeader',
  components: {
},
  data: () => ({
  }),
};
</script>