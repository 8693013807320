<template>
    <v-container ref="DocumentPdf" class="DocumentPdf" style="display: none;">
        <v-row class="RowStyle">
            <v-col sm="3" mb="3">
                <img src="../assets/images/acp_logo.jpg" class="shrink mr-2 align-end acp-nav-img" contain width="160px"/>
            </v-col>
            <v-col style="align-content: center;">
                <div style="display:flex; flex-direction: column;justify-content: center;">
                    <label class="acp-labelPdf acp-pdf-title" style="display: block;text-align: center;">{{$t('pdf.Title')}}</label>
                    <h4 class="acp-labelPdf acp-pdf-subtitle" style="display: block;text-align: center;width:336px">{{$t('pdf.SubTitle')}}</h4>
                </div>
            </v-col>
            <v-col sm="3" mb="3">
            </v-col>
        </v-row>
        <v-row class="RowStyle">
            <v-col sm="3" md="3">
                <div class="d-flex align-start justify-space-between">
                    <label class="acp-labelPdf-active acp-pdf-body">{{$t('pdf.TextDate')}}</label>
                </div>
                <div class="d-flex align-start justify-space-between">
                    <label class="acp-labelPdf-active acp-pdf-body">{{$t('pdf.TextTransaction')}}</label>
                </div>
            </v-col>
            <v-col sm="3" md="3">
                <div style="margin-left: -25px" class="d-flex align-start justify-space-between">
                    <label class="acp-labelPdf acp-pdf-body acp-pdf-capitalize">{{date}}</label>
                </div>
                <div style="margin-left: -25px" class="d-flex align-start justify-space-between">

                    <label class="acp-labelPdf acp-pdf-body">{{$t('pdf.TextEstimation')}}</label>
                </div>
                
            </v-col>
        </v-row>
        <v-row style="margin-top:0" class="RowStyle">
            <v-col sm="3" md="3">
                <div class="d-flex align-start justify-space-between" v-if="DataForPdf.segment.visible">
                    <label class="acp-labelPdf-active acp-pdf-body" >{{$t('pdf.Segment')}}</label>
                </div>
                <div class="d-flex align-start justify-space-between" v-if="DataForPdf.size.visible">
                    <label class="acp-labelPdf-active acp-pdf-body" >{{$t('pdf.Size')}}</label>
                </div>
                <div class="d-flex align-start justify-space-between" v-if="DataForPdf.condition.visible">
                    <label class="acp-labelPdf-active acp-pdf-body">{{$t('pdf.Condition')}}</label>
                </div>
            </v-col>
            <v-col sm="3" md="3">
                <div style="margin-left: -25px" class="d-flex align-start justify-space-between" v-if="DataForPdf.segment.visible">
                    <label class="acp-labelPdf acp-pdf-body">{{DataForPdf.segment.value}}</label>
                </div>
                <div style="margin-left: -25px" class="d-flex align-start justify-space-between" v-if="DataForPdf.size.visible">
                    <label class="acp-labelPdf acp-pdf-body">{{DataForPdf.size.value}}</label>
                </div>
                <div style="margin-left: -25px" class="d-flex align-start justify-space-between" v-if="DataForPdf.condition.visible">
                    <label class="acp-labelPdf acp-pdf-body">{{DataForPdf.condition.value}}</label>
                </div>
            </v-col>
        </v-row>
        <v-row style="margin-top:0" class="RowStyle">
            <v-col sm="3" md="3">
                <div class="d-flex align-start justify-space-between" v-if="DataForPdf.Reservation.visible">
                    <label class="acp-labelPdf-active acp-pdf-body">{{$t('pdf.Reservation')}}</label>
                </div>
                <div class="d-flex align-start justify-space-between" v-if="DataForPdf.movement.visible">
                    <label class="acp-labelPdf-active acp-pdf-body">{{$t('pdf.movement')}}</label>
                </div>
            </v-col>
            <v-col sm="3" md="3">
                <div style="margin-left: -25px" class="d-flex align-start justify-space-between" v-if="DataForPdf.Reservation.visible">
                    <label class="acp-labelPdf acp-pdf-body">{{DataForPdf.Reservation.value}}</label>
                </div>
                <div style="margin-left: -25px" class="d-flex align-start justify-space-between" v-if="DataForPdf.movement.visible">
                    <label class="acp-labelPdf acp-pdf-body">{{DataForPdf.movement.value}}</label>
                </div>
            </v-col>
        </v-row>
        <v-row class="RowStyle">
            <table>
                <tr v-if="title1_data.length>0 || title2_data.length>0 || title3_data.length>0">
                    <th class="acp-labelPdf-active acp-pdf-body" style="text-align: left;">{{$t('pdf.Textspan8')}}</th>
                    <th class="acp-labelPdf-active acp-pdf-body" style="text-align: right;">{{$t('pdf.Textspan9')}}</th>
                </tr>
                <tr v-if="ListIsVisible(title1_data)">
                    <th class="acp-labelPdf-active acp-pdf-body" style="text-align: left;">{{$t(title1)}}</th>
                    <th class="acp-labelPdf-active acp-pdf-body" style="text-align: right;"></th>
                </tr>
                <tr v-for="item in title1_data" :key="item.campo">
                    <td v-if="item.visible" class="acp-labelPdf acp-pdf-body">{{item.nombre}}</td>
                    <td v-if="item.visible" class="acp-labelPdf acp-pdf-body" style="text-align: right;">${{item.valor}}</td>
                </tr>
                <tr v-if="ListIsVisible(title2_data)">
                    <th class="acp-labelPdf-active acp-pdf-body" style="text-align: left;">{{$t(title2)}}</th>
                    <th class="acp-labelPdf-active acp-pdf-body" style="text-align: right;"></th>
                </tr>
                <tr v-for="item in title2_data" :key="item.campo">
                    <td v-if="item.visible" class="acp-labelPdf acp-pdf-body">{{item.nombre}}</td>
                    <td v-if="item.visible" class="acp-labelPdf acp-pdf-body" style="text-align: right;">${{item.valor}}</td>
                </tr>
                <tr v-if="ListIsVisible(title3_data)">
                    <th class="acp-labelPdf-active acp-pdf-body" style="text-align: left;">{{$t(title3)}}</th>
                    <th class="acp-labelPdf-active acp-pdf-body" style="text-align: right;"></th>
                </tr>
                <tr v-for="item in title3_data" :key="item.campo">
                    <td v-if="item.visible" class="acp-labelPdf acp-pdf-body">{{item.nombre}}</td>
                    <td v-if="item.visible" class="acp-labelPdf acp-pdf-body" style="text-align: right;">${{item.valor}}</td>
                </tr>
                <tr>
                    <th class="acp-labelPdf-active acp-pdf-body" style="text-align: left;">{{$t('pdf.Textspan10')}}</th>
                    <th class="acp-labelPdf-active acp-pdf-body" style="text-align: right;">${{totalEstimate}}</th>
                </tr>
            </table>
        </v-row>
        <v-row class="RowStyle">
            <span class="acp-footer" v-html="$t('app.footerText')">
            </span>
        </v-row>
    </v-container>
</template>
<script>
    export default {
      props:["date", "DataForPdf", "totalEstimate", "title1", "title1_data", "title2", "title2_data", "title3", "title3_data"],
      name: 'pdf',
      methods:{
        ListIsVisible: function(DataList){
            return DataList.find((e) => e.visible) != undefined;
        }
      }
    }
</script>
<style>
    .row.RowStyle div {
        padding: 0px;
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }
    table, th, td {
        border: 1px solid #000000;
    }
    th, td{
        padding: 7px;
    }
    td{
        padding-left: 30px;
    }
    .RowStyle{
        width: 720px;margin: 0px 40px;
    }
    .acp-labelPdf{
        color: #000000 !important;
    }
    .acp-labelPdf-active{
        font-weight: bold;
        color: #003e6b !important;
    }
    .acp-pdf-capitalize{
        text-transform: capitalize;
    }
    .acp-pdf-footer{
        font-family: "Open Sans";
        font-size: 9px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #343332;
        display: block;
        text-align: justify;
    }
    .acp-pdf-title{
        font-weight: bold;
        font-family: "Open Sans";
        font-size: 11px;
    }
    .acp-pdf-subtitle{
        font-weight: bold;
        font-family: "Open Sans";
        font-size: 17px;
    }
    .acp-pdf-body{
        font-family: "Open Sans";
        font-size: 11px;
    }
</style>