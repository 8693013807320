<template>
    <v-radio-group class="mt-0" :id="TextName" :name="TextName" v-model="model">
        <template>
          <v-container>
            <v-row>
              <v-col cols="6" sm="6" md="6" class="acp-radio-group">
                <v-flex class="d-flex align-center justify-space-between" :class="model == 'true' ? 'acp-radio-select' : 'acp-radio'">
                  <label class="acp-label-radio" :for="'Rsi'+TextName">{{$t('CustomRadio.value1')}}</label>
                  <v-radio :id="'Rsi'+TextName" value="true"></v-radio> 
                </v-flex>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="acp-radio-group">
                <v-flex class="d-flex align-center justify-space-between" :class="model == 'false' ? 'acp-radio-select' : 'acp-radio'">
                  <label class="acp-label-radio" :for="'Rno'+TextName">{{$t('CustomRadio.value2')}}</label>
                  <v-radio :id="'Rno'+TextName" value="false"></v-radio>
                </v-flex>
              </v-col>
            </v-row>
          </v-container>
        </template>  
      </v-radio-group>
</template>
<script>
    export default {
      props:['name', 'Rvalue'],
      emits: ['change-value'],
      name: 'CustomRadio',
      computed:{
        TextName(){
          return this.name.toLowerCase();
        },
        model: {
          get: function () {
            return this.Rvalue
          },
          set: function (newValue) {
            this.$emit('change-value', newValue);
          }
        }
      },
    }
</script>