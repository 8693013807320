import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './assets/css/main.css'
import i18n from './i18n'
import router from './router'

Vue.config.productionTip = false

 router.beforeEach((to, from, next)=>{
  let language = to.params.lang;
  if(!language)
    language = "es"

  i18n.locale = language

  next()
}) 


new Vue({
  vuetify,
  i18n,
  router,
  render: h => h(App)
})
.$mount('#app')
