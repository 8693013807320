<template>
  <v-container>
    <v-row class="acp-separador"></v-row>
    <v-row no-gutters class="acp-movimiento-nav">
      <v-flex xs12 md12 class="d-flex align-center justify-space-between" no-gutters>
        <v-flex row no-gutters class="d-flex justify-end">
          <v-img v-if="pagina==1" src="../assets/images/Step1.svg" class="shrink mr-2 align-end acp-nav-img" contain></v-img>
          <v-img v-if="pagina==2" src="../assets/images/Stepcheck.svg" class="shrink mr-2 align-end acp-nav-img" contain></v-img>
          <h1 class="acp-step-text" :class="pagina == 1 ? 'acp-text-active' : 'acp-text-disable'" v-html="$t('calculadora.acp-step-1')"></h1>         
        </v-flex>
        <v-flex row no-gutters class="d-flex justify-center">
          <v-img src="../assets/images/Line.svg" class="shrink acp-nav-img hidden-xs-only hidden-sm-only" contain height="10px"></v-img>
        </v-flex>
        <v-flex row no-gutters class="d-flex justify-start">
          <v-img v-if="pagina==1" src="../assets/images/Step2.svg" class="shrink mr-2 align-end acp-nav-img" contain></v-img>
          <v-img v-if="pagina==2" src="../assets/images/Step2on.svg" class="shrink mr-2 align-end acp-nav-img" contain></v-img>
          <h1 class="acp-step-text" :class="pagina == 2 ? 'acp-text-active' : 'acp-text-disable'" v-html="$t('calculadora.acp-step-2')"></h1>         
        </v-flex>
      </v-flex>
    </v-row>
    <v-row class="acp-separador"></v-row>
    <v-form ref="formServiciosRecurrentes" :disabled="isLoading">
    <template v-if="pagina==1">
      <v-container>
        <v-layout row wrap>
          <v-flex v-if="TypeMovement==0" xs12 md12 class="acp-titulo acp-form-item" no-gutters>
            {{$t('calculadora.title')}}
          </v-flex>
          <v-flex v-if="TypeMovement==1" xs12 md12 class="acp-titulo acp-form-item" no-gutters>
            {{$t('calculadora.titleMov')}}
          </v-flex>
          <v-flex sx12 md12 class="acp-form-item mb-5">
            <span v-html="$t('calculadora.form-warning')"></span>
          </v-flex>
          <v-flex xs12 md12 class="acp-separador-sub">
          </v-flex>
          <v-flex xs12 md6 class="acp-form-item">
            <v-tooltip top color="#D8E8F3">
              <template v-slot:activator="{ on, attrs }" close-delay="5000">
                <v-flex row no-gutters>
                  <label for="mercado" class="acp-label">{{$t('calculadora.SegmentTitle')}}<span class="acp-span-red">*</span></label>
                      <v-flex class="d-flex justify-end mb-6 bg-surface-variant">
                      <v-img src="../assets/images/info.svg" class="shrink mr-2 align-end" contain width="25px"
                      v-bind="attrs" v-on="on">
                      </v-img>
                      </v-flex>
                </v-flex>
              </template>
              <span class="acp-tooltip-span">{{$t('calculadora.SegmentTitle-tooltip')}}</span>
            </v-tooltip>
            <v-select id="mercado" dense outlined single-line :placeholder="$t('calculadora.selectPlaceHolder')" v-model="formData.segment.value" :rules="formDataRules.segmentRules" 
                :items="ListaSegmentos"
                item-value="id"
                item-text="name">
              <template v-slot:selection="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:item="{item, attrs, on}">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-radio :id="item.name" :name="item.name" :off-icon="active ? '$radioOn' : '$radioOff'"></v-radio>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters >
                        {{ item.name }}
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template> 
              <template v-slot:append>
                <v-img src="../assets/images/Vector.svg" class="shrink mr-2 align-end acp-input-select-chevron" contain></v-img>
              </template> 
            </v-select>
          </v-flex>
          <v-flex xs12 md6 class="acp-form-item mb-3" v-if="this.formData.size.visible">
            <v-tooltip top color="#D8E8F3">
              <template v-slot:activator="{ on, attrs }" close-delay="5000">
                <v-flex row no-gutters>
                  <label for="buques" class="acp-label">{{formData.segment.value == 'Embarcacionesmenores' ? $t('calculadora.ASEMTitle') : $t('calculadora.vesselTitle')}}<span class="acp-span-red">*</span></label>
                  <v-flex class="d-flex justify-end mb-6 bg-surface-variant">
                    <v-img src="../assets/images/info.svg" class="shrink mr-2 align-end" contain width="25px"
                    v-bind="attrs" v-on="on"></v-img>
                  </v-flex>
                </v-flex>
              </template>
              <span class="acp-tooltip-span">{{$t('calculadora.vesselTitle-tooltip')}}</span>
            </v-tooltip>
            <v-select id="buques" dense outlined single-line :placeholder="$t('calculadora.selectPlaceHolder')" v-model="formData.size.value" :rules="formDataRules.sizeRules" 
                :items="ListaBuques"
                item-value="id"
                item-text="name"
                :no-data-text="$t('calculadora.selectEmpySize')">
              <template v-slot:selection="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:item="{item, attrs, on}">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-radio :id="item.name" :name="item.name" :off-icon="active ? '$radioOn' : '$radioOff'"></v-radio>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters >
                        {{ item.name }}
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template> 
              <template v-slot:append>
                <v-img src="../assets/images/Vector.svg" class="shrink mr-2 align-end acp-input-select-chevron" contain></v-img>
              </template> 
            </v-select>
            {{ ListaBuques.find((x) => x.id === formData.size.value) != undefined ? ListaBuques.find((x) => x.id === formData.size.value).description : "" }}
          </v-flex>
          <v-flex xs12 md12 class="acp-separador-sub">
          </v-flex>
          <v-flex xs12 md6 v-if="this.formData.condition.visible" class="acp-form-item">
            <v-tooltip top color="#D8E8F3">
              <template v-slot:activator="{ on, attrs }" close-delay="5000">
                <v-flex row no-gutters>
                  <label for="condicion" class="acp-label">{{$t('calculadora.Condition')}}<span class="acp-span-red">*</span></label>
                  <v-flex class="d-flex justify-end mb-6 bg-surface-variant">
                    <v-img src="../assets/images/info.svg" class="shrink mr-2 align-end" contain width="25px"
                    v-bind="attrs" v-on="on"></v-img>
                  </v-flex>
                </v-flex>
              </template>
              <span class="acp-tooltip-span">{{$t('calculadora.Condition-tooltip')}}</span>
            </v-tooltip>
            <v-select id="condicion" dense outlined single-line :placeholder="$t('calculadora.selectPlaceHolder')" v-model="formData.condition.value" :rules="formDataRules.conditionRules"
            :items="ListaCondicion"
            item-value="id"
            item-text="name">
            <template v-slot:selection="{ item }">
                {{ item.name }}
            </template>
            <template v-slot:item="{item, attrs, on}">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-radio :id="item.name" :name="item.name" :off-icon="active ? '$radioOn' : '$radioOff'"></v-radio>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters >
                        {{ item.name }}
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template> 
              <template v-slot:append>
                <v-img src="../assets/images/Vector.svg" class="shrink mr-2 align-end acp-input-select-chevron" contain></v-img>
              </template> 
            </v-select>
          </v-flex>
          <v-flex xs12 md6 class="acp-form-item" v-if="this.formData.hasBooking.visible">
            <v-tooltip top color="#D8E8F3">
              <template v-slot:activator="{ on, attrs }" close-delay="5000">
                <v-flex row no-gutters>
                  <label for="radio-group" class="acp-label">{{$t('calculadora.ReservationTitle')}}<span class="acp-span-red">*</span></label>
                  <v-flex class="d-flex justify-end bg-surface-variant">
                    <v-img src="../assets/images/info.svg" class="shrink mr-2 align-end" contain width="25px"
                    v-bind="attrs" v-on="on"></v-img>
                  </v-flex>
                </v-flex>
              </template>
              <span class="acp-tooltip-span">{{$t('calculadora.ReservationTitle-tooltip')}}</span>
            </v-tooltip>
            <CustomRadio :name="'Reservacion'" :Rvalue="formData.hasBooking.value" @change-value="ReservacionChanged($event)"></CustomRadio>
          </v-flex>
          <v-flex xs12 md12 class="acp-separador-sub">
          </v-flex>
          <v-flex xs12 md6 class="acp-form-item" v-if="this.formData.loadedlTEU.visible">
            <v-tooltip top color="#D8E8F3">
              <template v-slot:activator="{ on, attrs }" close-delay="5000">
                <v-flex row no-gutters>
                  <label for="teusCarga" class="acp-label">{{$t('calculadora.teusLoadTitle')}}<span class="acp-span-red">*</span></label>
                  <v-flex class="d-flex justify-end mb-6 bg-surface-variant">
                    <v-img src="../assets/images/info.svg" class="shrink mr-2 align-end" contain width="25px"
                    v-bind="attrs" v-on="on"></v-img>
                  </v-flex>
                </v-flex>
              </template>
              <span class="acp-tooltip-span">{{$t('calculadora.teusLoadTitle-tooltip')}}</span>
            </v-tooltip>
            <v-select id="teusCarga" dense outlined single-line :placeholder="$t('calculadora.selectPlaceHolder')" v-model="formData.loadedlTEU.value" :rules="formDataRules.loadedlTEURules"
                :items="ListaCargaPorcentajes"
                item-value="id"
                item-text="name">
            <template v-slot:selection="{ item }">
                {{ item.name }}
            </template>
            <template v-slot:item="{item, attrs, on}">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-radio :id="item.name" :name="item.name" :off-icon="active ? '$radioOn' : '$radioOff'"></v-radio>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters >
                        {{ item.name }}
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>  
              <template v-slot:append>
                <v-img src="../assets/images/Vector.svg" class="shrink mr-2 align-end acp-input-select-chevron" contain></v-img>
              </template> 
            </v-select>
          </v-flex>
          <v-flex xs12 md6 class="acp-form-item" v-if="this.formData.emptyTEU.visible">
            <v-tooltip top color="#D8E8F3">
              <template v-slot:activator="{ on, attrs }" close-delay="5000">
                <v-flex row no-gutters>
                  <label for="teusVacios" class="acp-label">{{$t('calculadora.teusEmptyTitle')}}<span class="acp-span-red">*</span></label>
                  <v-flex class="d-flex justify-end mb-6 bg-surface-variant">
                    <v-img src="../assets/images/info.svg" class="shrink mr-2 align-end" contain width="25px"
                    v-bind="attrs" v-on="on"></v-img>
                  </v-flex>
                </v-flex>
              </template>
              <span class="acp-tooltip-span">{{$t('calculadora.teusEmptyTitle-tooltip')}}</span>
            </v-tooltip>
            <v-select id="teusVacios" dense outlined single-line :placeholder="$t('calculadora.selectPlaceHolder')" v-model="formData.emptyTEU.value" :rules="formDataRules.emptyTEURules"
              :items="ListaCargaPorcentajes"
              item-value="id"
              item-text="name">
            <template v-slot:selection="{ item }">
                {{ item.name }}
            </template>
            <template v-slot:item="{item, attrs, on}">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-radio :id="item.name" :name="item.name" :off-icon="active ? '$radioOn' : '$radioOff'"></v-radio>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters >
                        {{ item.name }}
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template> 
              <template v-slot:append>
                <v-img src="../assets/images/Vector.svg" class="shrink mr-2 align-end acp-input-select-chevron" contain></v-img>
              </template> 
            </v-select>
          </v-flex>
          <v-flex xs12 md12 class="acp-form-item acp-Error-text" mb-5 v-if="TeusError">
            {{$t('calculadora.teusWarning')}}
          </v-flex>
        </v-layout>
        <v-layout v-if="TypeMovement==0" row wrap>
          <v-flex xs12 md12 class="acp-titulo acp-form-item" v-if="formData.hasPortMovement.visible">
            {{$t('calculadora.title2')}}
          </v-flex>
          <v-flex xs12 md12 class="acp-separador-sub">
          </v-flex>
          <v-flex xs12 md6 class="acp-form-item" v-if="formData.hasPortMovement.visible">
            <v-tooltip top color="#D8E8F3">
              <template v-slot:activator="{ on, attrs }" close-delay="5000">
                <v-flex class="d-flex align-start justify-space-between" no-gutters> 
                  <label for="movimientoportuario" class="acp-label">{{$t('calculadora.title2_question')}} <span class="acp-label-span ml-2">&nbsp;{{$t('calculadora.title2_question_span')}}</span></label>
                  <v-flex class="d-flex justify-end mb-3 bg-surface-variant" row no-gutters> 
                    <v-img src="../assets/images/info.svg" class="shrink mr-2 align-end" contain width="25px"
                    v-bind="attrs" v-on="on"></v-img>
                  </v-flex>
                </v-flex>
              </template>
              <span class="acp-tooltip-span">{{$t('calculadora.title2_toolTip')}}</span>
            </v-tooltip>
            <CustomRadio :name="'movimientoportuario'" :Rvalue="formData.hasPortMovement.value" @change-value="movimientoPortuarioChanged($event)"></CustomRadio>
          </v-flex>
          <v-flex xs12 md6 class="acp-form-item" v-if="this.formData.movement.visible">
            <v-tooltip top color="#D8E8F3">
              <template v-slot:activator="{ on, attrs }" close-delay="5000">
                <v-flex row no-gutters>
                  <label for="movPortuario" class="acp-label mb-6">{{$t('calculadora.portMovement')}}</label>
                  <v-flex class="d-flex justify-end mb-6 bg-surface-variant">
                        <v-img src="../assets/images/info.svg" class="shrink mr-2 align-end" contain width="25px"
                        v-bind="attrs" v-on="on"></v-img>
                      </v-flex>
                </v-flex>
              </template>
              <span class="acp-tooltip-span">{{$t('calculadora.portMovement-toolTip')}}</span>
            </v-tooltip>
            <v-select id="movPortuario" v-model="formData.movement.value" :rules="formDataRules.MovimientoPortuarioRules" dense outlined single-line :placeholder="$t('calculadora.selectPlaceHolder')"
              :items="ListaMovimientoPortuario"
              item-value="id"
              item-text="name">
            <template v-slot:selection="{ item }">
                {{ item.name }}
            </template>
            <template v-slot:item="{item, attrs, on}">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-radio :id="item.name" :name="item.name" :off-icon="active ? '$radioOn' : '$radioOff'"></v-radio>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters >
                        {{ item.name }}
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template> 
              <template v-slot:append>
                <v-img src="../assets/images/Vector.svg" class="shrink mr-2 align-end acp-input-select-chevron" contain></v-img>
              </template> 
            </v-select>
          </v-flex>

        </v-layout>
        <v-row class="acp-separador"></v-row>
        <v-layout row wrap>
          <v-flex xs6 md6 class="acp-form-item">
            <span class="acp-label-span">{{$t('calculadora.preliminaryEstimate')}}</span><br>
            <span class="acp-span-money" v-if="!isLoading">${{estimate}}</span>
            <span class="acp-spinner" v-if="isLoading"></span>
          </v-flex>
          <v-flex xs6 md6 style="text-align: end;" class="acp-form-item">
            <v-btn id="transitoSiguiente" name="transitoSiguiente" class="acp-btn acp-btn-primary" @click="ValidateForm(2)">
              {{$t('calculadora.btnNext')}}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
    <template v-if="pagina==2">
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-flex no-gutters row class="mb-6">
              <label class="acp-title-grey">{{$t('calculadora.segment')}}:</label><span class="acp-label pl-2 acp-underLine">{{SelectSegmentName}}</span>
            </v-flex>
            <v-flex xs12 md12 class="acp-titulo" no-gutters v-if="formData.hasMoorageAnchorage.visible">
              {{$t('calculadora.incidentalFields')}}
            </v-flex>
            <v-flex xs12 md12 class="acp-separador-sub2" v-if="formData.hasMoorageAnchorage.visible">
            </v-flex>
            <v-flex no-gutters row v-if="formData.hasMoorageAnchorage.visible">
              <v-tooltip top color="#D8E8F3">
                <template v-slot:activator="{ on, attrs }" close-delay="5000">
                  <v-flex row no-gutters>
                    <label for="fondeo" class="acp-label">{{$t('calculadora.anchorageTitle')}}<span class="acp-span-red">*</span></label>
                      <v-flex class="d-flex justify-end mb-6 bg-surface-variant">
                        <v-img src="../assets/images/info.svg" class="shrink mr-2 align-end" contain width="25px"
                        v-bind="attrs" v-on="on"></v-img>
                      </v-flex>
                  </v-flex>
                </template>
                <span class="acp-tooltip-span">{{$t('calculadora.anchorageTitle-toolTip')}}</span>
              </v-tooltip>
            </v-flex>
            <CustomRadio :name="'fondeo'" :Rvalue="formData.hasMoorageAnchorage.value" @change-value="fondeoChanged($event)" v-if="formData.hasMoorageAnchorage.visible"></CustomRadio>
            <v-flex xs12 md12 class="acp-titulo" no-gutters>
              {{$t('calculadora.optionalFields')}}
            </v-flex>
            <v-flex xs12 md12 class="acp-separador-sub2" v-if="formData.hasPhotography.visible">
            </v-flex>
            <v-flex no-gutters row v-if="formData.hasPhotography.visible">
              <v-tooltip top color="#D8E8F3">
                <template v-slot:activator="{ on, attrs }" close-delay="5000">
                  <v-flex row no-gutters>
                    <label for="fotografia" class="acp-label">{{$t('calculadora.PhotographyTitle')}}</label>
                      <v-flex class="d-flex justify-end mb-6 bg-surface-variant">
                        <v-img src="../assets/images/info.svg" class="shrink mr-2 align-end" contain width="25px"
                        v-bind="attrs" v-on="on"></v-img>
                      </v-flex>
                  </v-flex>
                </template>
                <span class="acp-tooltip-span">{{$t('calculadora.PhotographyTitle-toolTip')}}</span>
              </v-tooltip>
            </v-flex>
            <CustomRadio :name="'fotografia'" :Rvalue="formData.hasPhotography.value" @change-value="fotografiaChanged($event)" v-if="formData.hasPhotography.visible"></CustomRadio>
            <v-flex xs12 md12 class="acp-separador-sub" v-if="formData.hasBoarding.visible">
            </v-flex>
            <v-flex row no-gutters v-if="formData.hasBoarding.visible">
              <v-tooltip top color="#D8E8F3">
                <template v-slot:activator="{ on, attrs }" close-delay="5000">
                  <v-flex row no-gutters>
                    <label for="Embarque" class="acp-label">{{$t('calculadora.passengersTitle')}}</label>
                      <v-flex class="d-flex justify-end mb-6 bg-surface-variant">
                        <v-img src="../assets/images/info.svg" class="shrink mr-2 align-end" contain width="25px"
                        v-bind="attrs" v-on="on"></v-img>
                      </v-flex>
                  </v-flex>
                </template>
                <span class="acp-tooltip-span">{{$t('calculadora.passengersTitle-toolTip')}}</span>
              </v-tooltip>
            </v-flex>
            <CustomRadio :name="'Embarque'" :Rvalue="formData.hasBoarding.value" @change-value="EmbarqueChanged($event)" v-if="formData.hasBoarding.visible"></CustomRadio>
            <v-flex xs12 md12 class="acp-separador-sub" v-if="formData.hasSpecialPilotage.visible">
            </v-flex>
            <v-flex row no-gutters v-if="formData.hasSpecialPilotage.visible">
              <v-tooltip top color="#D8E8F3">
                <template v-slot:activator="{ on, attrs }" close-delay="5000">
                  <v-flex row no-gutters>
                    <label for="Practicaje" class="acp-label">{{$t('calculadora.PracticeTitle')}}</label>
                    <v-flex class="d-flex justify-end mb-6 bg-surface-variant">
                        <v-img src="../assets/images/info.svg" class="shrink mr-2 align-end" contain width="25px"
                        v-bind="attrs" v-on="on"></v-img>
                      </v-flex>
                    </v-flex>
                </template>
                <span class="acp-tooltip-span">{{$t('calculadora.PracticeTitle-toolTip')}}</span>
              </v-tooltip>
            </v-flex>
            <CustomRadio :name="'Practicaje'" :Rvalue="formData.hasSpecialPilotage.value" @change-value="PracticajeChanged($event)" v-if="formData.hasSpecialPilotage.visible"></CustomRadio>
            <v-flex xs12 md12 class="acp-separador-sub" v-if="formData.hasTransportation.visible">
            </v-flex>
            <v-flex row no-gutters v-if="formData.hasTransportation.visible">
              <v-tooltip top color="#D8E8F3">
                <template v-slot:activator="{ on, attrs }" close-delay="5000">
                  <v-flex xs12 md12>
                    <v-flex class="d-flex align-start justify-space-between" no-gutters> 
                      <label class="acp-label mb-3">{{$t('calculadora.TransportationTitle')}}</label>
                      <v-flex no-gutters class="d-flex justify-end mb-3"> 
                          <v-img src="../assets/images/info.svg" class="shrink mr-2 align-end" contain width="25px"
                          v-bind="attrs" v-on="on"></v-img>
                      </v-flex>
                    </v-flex>
                  </v-flex>
                </template>
                <span class="acp-tooltip-span">{{$t('calculadora.TransportationTitle-toolTip')}}</span>
              </v-tooltip>
            </v-flex>
            <CustomRadio :name="'Transportation'" :Rvalue="formData.hasTransportation.value" @change-value="TrasportationChanged($event)" v-if="formData.hasTransportation.visible"></CustomRadio>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-flex row class="justify-end" no-gutters>
              <v-btn :disabled="isLoading" class="acp-btn acp-btn-active" @click="ExportDocumentPdf()">
                {{$t('calculadora.ExportBtn')}}
                <v-img src="../assets/images/arrow-right.svg" class="shrink mr-2 align-end" contain width="25px"></v-img>
              </v-btn>
              <!-- <v-img src="../assets/images/house.svg" class="shrink ml-2 align-end" contain width="58px"></v-img> -->
            </v-flex>
            <v-flex class="acp-separador">
            </v-flex>
            <v-flex row class="mt-20" no-gutters>
              <label class="acp-title-grey">{{$t('calculadora.detailsTitle')}}</label>
            </v-flex>
            <v-flex row class="acp-box-grey" no-gutters>

              <!-- ListaServiciosRecurrentes -->
              <DetailsSection :title="'calculadora.detailsTitleSub'" :isLoading="isLoading" :ListItems="detalles.ListaServiciosRecurrentes" :show="'true'"></DetailsSection>
              <!-- ListaServiciosMovimientosPuertarios -->
              <DetailsSection :title="'calculadora.detailsTitleSub3'" :isLoading="isLoading" :ListItems="detalles.ListaServiciosMovimientosPuertarios" :show="formData.hasPortMovement.value"></DetailsSection>
              <!-- ListaServiciosIncindentales -->
              <DetailsSection :title="'calculadora.detailsTitleSub2'" :isLoading="isLoading" :ListItems="detalles.ListaServiciosIncindentales" :show="'true'"></DetailsSection>
              <!---->

              <v-flex class="acp-separador" no-gutters>
              </v-flex>
              <v-flex xs12 md12>
                <span class="acp-label-span">{{$t('calculadora.preliminaryEstimate')}}</span><br>
                <span class="acp-span-money" v-if="!isLoading">${{detalles.estimateTotal}}</span>
                <span class="acp-spinner" v-if="isLoading"></span>
              </v-flex>
            </v-flex>
            <v-flex class="acp-separador" no-gutters>
            </v-flex>
            <v-flex row no-gutters>
              <v-flex xs6 md6 class="acp-form-item">
                <v-btn class="acp-btn acp-btn-white" @click="irPagina(1)">
                  {{$t('calculadora.btnBack')}}
                </v-btn>
              </v-flex>
              <v-flex xs6 md6 class="acp-form-item">
                <v-btn class="acp-btn acp-btn-primary" @click="modalFormalizar = true">
                {{$t('calculadora.Formalize')}}
              </v-btn>
              </v-flex>
            </v-flex>
            <v-flex class="acp-separador" no-gutters>
            </v-flex>
            <v-flex row no-gutters>
              <v-flex xs12 md12 class="acp-form-item">
                <a class="acp-link-grey" :href="URL_VUMPA" target="_blank">{{$t('calculadora.ModalFormalize-link')}} <span class="acp-link-active">{{$t('calculadora.ModalFormalize-link2')}}</span></a>
              </v-flex>
              <v-flex xs12 md12 class="acp-form-item">
              </v-flex>
            </v-flex>
          </v-col>
        </v-row> 
      </v-container>
    </template>
    </v-form>
    <v-row class="acp-separador"></v-row>
    <v-dialog v-model="showformWarning" ref="FormMessage" max-width="683px">
      <v-card>
        <v-card-title>
        <v-flex class="d-flex align-center justify-center" no-gutters>
          <v-img src="../assets/images/warning.svg" class="shrink mr-2 align-end" contain width="97px"></v-img>
        </v-flex>
        </v-card-title>
        <v-card-text>
        <v-container class="bg-surface-variant">
          <v-row justify="center">
            <v-col cols="12" sm="9" md="9">
              <h3 class="acp-dialog-title">{{$t('DialogWarning.Title')}}</h3>
            </v-col>
            <v-col cols="12" sm="9" md="9">
              <span class="acp-dialog-span-center-2">
              {{$t('DialogWarning.message')}}
              </span>
            </v-col>
          </v-row>
        </v-container>
        </v-card-text>
        <v-card-actions>
          <v-flex row no-gutters>
            <v-flex xs12 md12 style="text-align: center;">
              <v-btn class="acp-btn-sm acp-btn-white" @click="showformWarning = false">
                {{$t('DialogWarning.closeBtn')}}
              </v-btn>
            </v-flex>
            <v-flex xs6 md6 class="acp-separador">
            </v-flex>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="exportar" persistent max-width="690px">
      <v-form ref="formEnviarCorreo" :disabled="isLoading">
      <v-card>
        <v-card-title>
          <v-flex class="d-flex align-center justify-end" no-gutters @click="exportar = false">
            <v-img src="../assets/images/x.svg" class="shrink mr-2 align-end" contain width="26px"></v-img>
          </v-flex>
          <v-container class="bg-surface-variant" fluid>
            <v-row justify="center">
              <v-col cols="12">
                <h3 class="acp-dialog-title">{{$t('calculadora.ModalExportarTitle')}}</h3>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-container class="bg-surface-variant acp-dialog-container">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="acp-dialog-span">
                  {{$t('calculadora.ModalExportarspan1')}}
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters>
                        <v-col cols="12">
                          <v-text-field class="acp-dialog-input"
                            outlined
                            dense
                            :placeholder="$t('calculadora.ModalExportarPlaceHolderEmail')"
                            type="email"
                            required
                            :rules="formEnviarCorreo.CorreoRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <span class="acp-dialog-span-bold" >{{$t('calculadora.ModalExportarSubTile')}}</span>
                <span class="acp-dialog-span">
                  {{$t('calculadora.ModalExportarspan2')}}<a :href="URL_VUMPA" target="_blank" class="acp-link-active-sm">{{$t('calculadora.ModalExportarspan2-link')}} <v-img src="../assets/images/link.svg" contain class="acp-dialog-link-img"></v-img></a>
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
              <v-flex row no-gutters>
                <v-flex xs6 md6 class="acp-form-item" style="text-align: end;">
                  <v-btn class="acp-btn-sm acp-btn-white" @click="exportar = false">
                    {{$t('calculadora.closeBtn')}}
                  </v-btn>
                </v-flex>
                <v-flex xs6 md6 class="acp-form-item" style="text-align: start;">
                  <v-btn class="acp-btn-sm acp-btn-primary" @click="ExportDocumentPdf()">
                    {{$t('calculadora.confirmBtn')}}
                  </v-btn>
                </v-flex>
                <v-flex xs6 md6 class="acp-separador">
                </v-flex>
              </v-flex>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="modalFormalizar" persistent max-width="690px">
      <v-card>
        <v-card-title>
          <v-flex class="d-flex align-center justify-end" no-gutters @click="modalFormalizar = false">
            <v-img src="../assets/images/x.svg" class="shrink mr-2 align-end" contain width="26px"></v-img>
          </v-flex>
          <v-container class="bg-surface-variant" fluid>
            <v-row justify="center">
              <v-col cols="12">
                <h3 class="acp-dialog-title">{{$t('calculadora.ModalFormalizeTitle')}}</h3>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-container class="bg-surface-variant acp-dialog-container">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="acp-dialog-span-center">
                  {{$t('calculadora.ModalFormalizespan')}}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
              <v-flex row no-gutters>
                <v-flex xs6 md6 class="acp-form-item" style="text-align: end;">
                  <v-btn class="acp-btn-sm acp-btn-white" @click="modalFormalizar = false">
                    {{$t('calculadora.closeBtn')}}
                  </v-btn>
                </v-flex>
                <v-flex xs6 md6 class="acp-form-item" style="text-align: start;">
                  <v-btn class="acp-btn-sm acp-btn-primary" @click="FormalizeAction()">
                    {{$t('calculadora.confirmBtn')}}
                  </v-btn>
                </v-flex>
                <v-flex xs6 md6 class="acp-separador">
                </v-flex>
              </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <pdf :date="Todate" :totalEstimate="detalles.estimateTotal"
    :DataForPdf="DataForPdf"
    :title1="'calculadora.detailsTitleSub'" :title1_data="detalles.ListaServiciosRecurrentes"
    :title2="'calculadora.detailsTitleSub3'" :title2_data="detalles.ListaServiciosMovimientosPuertarios"
    :title3="'calculadora.detailsTitleSub2'" :title3_data="detalles.ListaServiciosIncindentales"
    ></pdf>
    <v-dialog v-model="showformError" ref="FormMessage" max-width="683px">
      <v-card>
        <v-card-title>
        <v-flex class="d-flex align-center justify-center" no-gutters>
          <v-img src="../assets/images/warning.svg" class="shrink mr-2 align-end" contain width="97px"></v-img>
        </v-flex>
        </v-card-title>
        <v-card-text>
        <v-container class="bg-surface-variant">
          <v-row justify="center">
            <v-col cols="12" sm="9" md="9">
              <h3 class="acp-dialog-title">{{$t('ErrorWarning.Title')}}</h3>
            </v-col>
            <v-col cols="12" sm="9" md="9">
              <span class="acp-dialog-span-center-2">
              {{$t('ErrorWarning.message')}}
              </span>
            </v-col>
          </v-row>
        </v-container>
        </v-card-text>
        <v-card-actions>
          <v-flex row no-gutters>
            <v-flex xs12 md12 style="text-align: center;">
              <v-btn class="acp-btn-sm acp-btn-white" @click="showformError = false">
                {{$t('ErrorWarning.closeBtn')}}
              </v-btn>
            </v-flex>
            <v-flex xs6 md6 class="acp-separador">
            </v-flex>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import CustomRadio from '../components/Forms/CustomRadio';
import DetailsSection from '../components/Forms/DetailsSection';
import pdf from '../components/pdf';
import i18n from '../i18n';

  export default {
    props:['TypeMovement'],
    name: 'Calculadora',
    components: {
      DetailsSection,
      CustomRadio,
      pdf
    },
    data: () => ({
      URL_VUMPA: process.env.VUE_APP_URL_VUMPA,
      URL_FORMALIZAR: process.env.VUE_APP_URL_FORMALIZAR,
      formData:{
        //Servicios Recurrentes
        segment: {
          value:"",
          visible: true
        },
        size: {
          value:"",
          visible: true
        },
        condition: {
          value:"",
          visible: true
        },
        loadedlTEU: {
          value:"",
          visible: false
        },
        emptyTEU: {
          value:"",
          visible: false
        },
        hasBooking: {
          value:"false",
          visible: false
        },
        hasPortMovement: {
          value:"false",
          visible: true
        },
        movement: {
          value:"",
          visible: true
        },
        // Servicios Opcionales
        hasMoorageAnchorage: {
          value:"false",
          visible: false
        },
        hasPhotography: {
          value:"false",
          visible: false
        },
        hasBoarding: {
          value:"false",
          visible: false
        },
        hasSpecialPilotage: {
          value:"false",
          visible: false
        },
        hasTransportation:{
          value: "false",
          visible: false
        }
      },
      formDataRules:{
        segmentRules: [
          value => {
            if(value?.length>0) return true;
            return i18n.t('formDataRules.segmentRules')
          }
        ],
        sizeRules: [
          value => {
            if(value?.length>0) return true;
            return i18n.t('formDataRules.sizeRules')
          }
        ],
        StartRules:[
          value => {
            if(value?.length>0) return true;
            return i18n.t('formDataRules.StartRules')
          }
        ],
        EndRules:[
          value => {
            if(value?.length>0) return true;
            return i18n.t('formDataRules.EndRules')
          }
        ],
        conditionRules: [
          value => {
            if(value?.length>0) return true;
            return i18n.t('formDataRules.conditionRules')
          }
        ],
        embarcacionRules:[
          value => {
            if(value?.length>0) return true;
            return i18n.t('formDataRules.embarcacionRules')
          }
        ],
        loadedlTEURules: [
          value => {
            if(value?.length>0) return true;
            return i18n.t('formDataRules.loadedlTEURules')
          }
        ],
        emptyTEURules: [
          value => {
            if(value?.length>0) return true;
            return i18n.t('formDataRules.emptyTEURules')
          }
        ],
        hasBookingRules:[
          value => {
            if(value?.length>0) return true;
            return i18n.t('formDataRules.hasBookingRules')
          }
        ],
        DireccionesRules:[
          value => {
            if(value?.length>0) return true;
            return i18n.t('formDataRules.DireccionesRules')
          }
        ],
        MovimientoPortuarioRules:[
          value => {
            if(value?.length>0) return true;
            return i18n.t('formDataRules.MovimientoPortuarioRules')
          }
        ],
        hasMoorageAnchorageRules: [
          value => {
            if(value?.length>0) return true;
            return i18n.t('formDataRules.hasMoorageAnchorageRules')
          }
        ],
        hasPhotographyRules: [
          value => {
            if(value?.length>0) return true;
            return i18n.t('formDataRules.hasPhotographyRules')
          }
        ]
      },
      formEnviarCorreo:{
        CorreoRules: [
          value => {
            if(value?.length>0) return true;
            return i18n.t('calculadora.ModalExportarRules');
          }
        ]
      },
      checkbox: true,
      radioGroup: 1,
      pagina: 1,
      isLoading: false,
      ListaSegmentos:[{id:'Contenedores', name: i18n.t('ListaSegmentos.Contenedores')}, {id:'LPG', name: i18n.t('ListaSegmentos.LPG')}, {id:'LNG', name: i18n.t('ListaSegmentos.LNG')}, {id:'Graneleros', name: i18n.t('ListaSegmentos.Granelero')}, {id:'Tanqueros', name: i18n.t('ListaSegmentos.Tanquero')}, {id:'Quimiqueros', name: i18n.t('ListaSegmentos.Quimiquero')}, {id:'Portavehiculos', name: i18n.t('ListaSegmentos.Portavehículo')}, {id:'Pasajeros', name: i18n.t('ListaSegmentos.Pasajero')}, {id:'CargaGeneral', name: i18n.t('ListaSegmentos.CargaGeneral')}, {id:'Refrigerados', name: i18n.t('ListaSegmentos.Refrigerado')}, {id:'Otros', name: i18n.t('ListaSegmentos.Otros')}, {id:'Embarcacionesmenores', name: i18n.t('ListaSegmentos.ASEM')}],
      ListaBuques:[],
      ListaCondicion: [{id:'Laden', name: i18n.t('ListaCondicion.Laden')},{id:'Ballast', name: i18n.t('ListaCondicion.Ballast')}],
      ListaMovimientoPortuario:[{id:'0', name: i18n.t('ListaMovimientoPortuario.before')},{id:'1', name: i18n.t('ListaMovimientoPortuario.after')},{id:'2', name: i18n.t('ListaMovimientoPortuario.both')}],
      ListaRutas:[{id:'Norte', name: i18n.t('ListaRutas.Nort')},{id:'Sur', name: i18n.t('ListaRutas.South')}],
      ListaCargaPorcentajes: [{id:'100', name:'100%'},{id:'90', name:'90%'},{id:'80', name:'80%'},{id:'70', name:'70%'},{id:'60', name:'60%'},{id:'50', name:'50%'},{id:'40', name:'40%'},{id:'30', name:'30%'},{id:'20', name:'20%'},{id:'10', name:'10%'},{id:'0', name:'0%'}],
      ListaLongitudEmbarcacion : [{id:'0', name: i18n.t('ListaLongitudEmbarcacion.i65')},{id:'1', name: i18n.t('ListaLongitudEmbarcacion.i65_80')},{id:'2', name: i18n.t('ListaLongitudEmbarcacion.i80_100')},{id:'3', name: i18n.t('ListaLongitudEmbarcacion.i100_125')},{id:'4', name: i18n.t('ListaLongitudEmbarcacion.i125')}],
      ListaIncioFin:[{id:'ASEA', name: i18n.t('ListaIncioFin.ASEA')}, {id:'ADK', name: i18n.t('ListaIncioFin.ADK')}, {id:'PSEA', name: i18n.t('ListaIncioFin.PSEA')}, {id:'PDK', name: i18n.t('ListaIncioFin.PDK')} ],
      showformWarning: false,
      showformError: false,
      exportar: false,
      modalFormalizar: false,
      estimate: "-.--",
      detalles: {
        estimateTotal: "-.--",
        ListaServiciosRecurrentes :[],
        ListaServiciosIncindentales:[],
        ListaServiciosMovimientosPuertarios:[]
      },
      TeusError: false
    }),
    computed:{
      FormExportarOpen(){
        return this.exportar;
      },
      SelectSegment(){
        return this.formData.segment.value;
      },
      SelectSegmentName(){
        return this.ListaSegmentos.find(i=>i.id==this.formData.segment.value).name;
      },
      DataForPdf(){
        return {
          segment:{
            value: this.ListaSegmentos.find(i=>i.id==this.formData.segment.value)?.name,
            visible: this.formData.segment.visible
          },
          size:{
            value: this.ListaBuques.find(i=>i.id==this.formData.size.value)?.name,
            visible: this.formData.size.visible
          },
          condition:{
            value: this.ListaCondicion.find(i=>i.id==this.formData.condition.value)?.name,
            visible: this.formData.condition.visible
          },
          Reservation:{
            value: this.formData.hasBooking.value == 'true' ? i18n.t('CustomRadio.value1') : i18n.t('CustomRadio.value2') ,
            visible: this.formData.hasBooking.visible,
          },
          movement:{
            value: this.ListaMovimientoPortuario.find(i=>i.id==this.formData.movement.value)?.name,
            visible: this.formData.movement.visible
          }
        };
      },
      Selectsize(){
        return this.formData.size.value;
      },
      TotalTEU(){
        return Number(this.formData.loadedlTEU.value) + Number(this.formData.emptyTEU.value);
      },
      CTypeMovement(){
        return this.TypeMovement;
      } ,
      ChasportMovement(){
        return this.formData.hasPortMovement.value;
      },
      TCondition(){
        return this.formData.condition.value;
      },
      CDataFormServiciosRecurrentes(){
        return JSON.stringify(this.FormatDataToApi(this.formData)); 
      },
      ListServices(){
        let resp = this.detalles.ListaServiciosRecurrentes.concat(this.detalles.ListaServiciosIncindentales);
        return resp.map(function (obj) {
          var rObj = {};
          rObj["nombre"] = obj.nombre;
          rObj["valor"] = obj.valor;
          return rObj;
        });
      },
      Todate(){
        moment.locale(i18n.locale);
        return moment().format('MMMM D[,] YYYY').toString();
      }
    },
    watch: {
      FormExportarOpen(value){
        if(!value){
          this.$refs.formEnviarCorreo.resetValidation();
        }
      },
      SelectSegment(value){
        this.formData.size.value = "";
        this.formData.condition.value = "";
        this.TransitoReglasParte1("hasBooking");
        this.TransitoReglasParte1("loadedlTEU");
        this.TransitoReglasParte1("emptyTEU");
        this.TransitoReglasParte1("condition");
        this.TransitoReglasParte1("emptyTEU");
        this.TransitoReglasParte1("size");
        this.TransitoReglasParte1("movement");
        this.TransitoReglasParte1("hasPortMovement");

        this.TransitoReglasParte2('hasMoorageAnchorage');
        this.TransitoReglasParte2('hasPhotography');
        this.TransitoReglasParte2('hasBoarding');
        this.TransitoReglasParte2('hasSpecialPilotage');
        //TEU's   CP/SUAB
        let RegularsId = "Regulars";
        let Regulars = i18n.t('ListaBuques.Regulars');
        let Regulars_description = i18n.t('ListaBuques.Regulars_description');

        let SupersId = "Supers";
        let Supers = i18n.t('ListaBuques.Supers');
        let Supers_description = i18n.t('ListaBuques.Supers_description');

        let NeoPanamaxId = "NeoPanamax";
        let NeoPanamax = i18n.t('ListaBuques.NeoPanamax');
        let NeoPanamax_description = i18n.t('ListaBuques.NeoPanamax_description');
        let DWT = i18n.t('ListaBuques.DWT');
        let CPSUAB = i18n.t('ListaBuques.CPSUAB');
        let id_menor = "Menor";
        let id_mayor = "Mayor";

        if(value == 'Contenedores'){
          let menor = " < 10,000 TEU's";
          let mayor = " ≥ 10,000 TEU's";
                                //this.ReplaceSegmentDatafunction(eslora, manga, addUnit, cpsuab, data, title="");     
          Regulars_description =  this.ReplaceSegmentData("611.4", "90.7", "TEU's 2,000", "19,094", Regulars_description);
          Supers_description =    this.ReplaceSegmentData("965.0", "105.8", "TEU's 5,000", "45,701", Supers_description);
          let NPMenor_desc =      this.ReplaceSegmentData("984.1", "158.2", "TEU's 9,000", "90,882", NeoPanamax_description, menor);
          let NPMayor_desc =      this.ReplaceSegmentData("1,096", "159", "TEU's 12,000", "116,295", NeoPanamax_description, mayor);

          this.ListaBuques = [
          {id:RegularsId, name: Regulars, panamaxlocks:'Panamax', description: Regulars_description, Unit:"TEU's", Unit_total:2000},
          {id:SupersId, name: Supers, panamaxlocks:'Panamax', description: Supers_description, Unit:"TEU's", Unit_total:5000},
          {id:NeoPanamaxId+id_menor, name: NeoPanamax + menor, panamaxlocks:'NeoPanamax', description: NPMenor_desc, Unit:"TEU's", Unit_total:9000},
          {id:NeoPanamaxId+id_mayor, name: NeoPanamax + mayor, panamaxlocks:'NeoPanamax', description: NPMayor_desc, Unit:"TEU's", Unit_total:12000}];
        }
        else if(value == 'LPG'){
          let menor = "";
                            //this.ReplaceSegmentDatafunction(eslora, manga, addUnit, cpsuab, data, title="");   
          Regulars_description = this.ReplaceSegmentData("525", "83", "22,000 m³", "16,043", Regulars_description);
          Supers_description = this.ReplaceSegmentData("755", "106", "80,000 m³", "43,319", Supers_description);
          NeoPanamax_description = this.ReplaceSegmentData("738.91", "120.21", "84,000 m³", "39,547", NeoPanamax_description, menor);

          this.ListaBuques = [
          {id:RegularsId, name: Regulars, panamaxlocks:'Panamax', description: Regulars_description, Unit:CPSUAB, Unit_total:16043},
          {id:SupersId, name: Supers, panamaxlocks:'Panamax', description: Supers_description, Unit:CPSUAB, Unit_total:43319},
          {id:NeoPanamaxId, name: NeoPanamax, panamaxlocks:'NeoPanamax', description: NeoPanamax_description, Unit:CPSUAB, Unit_total:39547}];
        }
        else if(value == 'LNG'){
          let menor = "";
                            //this.ReplaceSegmentDatafunction(eslora, manga, addUnit, cpsuab, data, title="");   
          Regulars_description = this.ReplaceSegmentData("592", "87", "29,614 m³", "19,090", Regulars_description);
          Supers_description = this.ReplaceSegmentData("751", "106", "167,286 m³", "35,512", Supers_description);
          NeoPanamax_description = this.ReplaceSegmentData("981", "158", "180,293 m³", "119,741", NeoPanamax_description, menor);

          this.ListaBuques = [
          {id:RegularsId, name: Regulars, panamaxlocks:'Panamax', description: Regulars_description, Unit:CPSUAB, Unit_total:19090},
          {id:SupersId, name: Supers, panamaxlocks:'Panamax', description: Supers_description, Unit:CPSUAB, Unit_total:35512},
          {id:NeoPanamaxId, name: NeoPanamax, panamaxlocks:'NeoPanamax', description: NeoPanamax_description, Unit:CPSUAB, Unit_total:119741}];

        }
        else if(value == 'Graneleros'){
          let menor = "";
                            //this.ReplaceSegmentDatafunction(eslora, manga, addUnit, cpsuab, data, title="");   
          Regulars_description = this.ReplaceSegmentData("556", "89", DWT + " 27,930", "14,717", Regulars_description);
          Supers_description = this.ReplaceSegmentData("656", "106", DWT + " 63,469", "29,664", Supers_description);
          NeoPanamax_description = this.ReplaceSegmentData("958", "148", DWT + " 181,259", "88,866", NeoPanamax_description, menor);

          this.ListaBuques = [
          {id:RegularsId, name: Regulars, panamaxlocks:'Panamax', description: Regulars_description, Unit:CPSUAB, Unit_total:14717},
          {id:SupersId, name: Supers, panamaxlocks:'Panamax', description: Supers_description, Unit:CPSUAB, Unit_total:29664},
          {id:NeoPanamaxId, name: NeoPanamax, panamaxlocks:'NeoPanamax', description: NeoPanamax_description, Unit:CPSUAB, Unit_total:88866}];

        }
        else if(value == 'Tanqueros'){

          let menor = "";
                            //this.ReplaceSegmentDatafunction(eslora, manga, addUnit, cpsuab, data, title="");   
          Regulars_description = this.ReplaceSegmentData("525", "78", "", "12,200", Regulars_description);
          Supers_description = this.ReplaceSegmentData("748", "106", "", "34,500", Supers_description);
          NeoPanamax_description = this.ReplaceSegmentData("820", "144", "", "52,200", NeoPanamax_description, menor);

          this.ListaBuques = [
          {id:RegularsId, name: Regulars, panamaxlocks:'Panamax', description: Regulars_description, Unit:CPSUAB, Unit_total:12181},
          {id:SupersId, name: Supers, panamaxlocks:'Panamax', description: Supers_description, Unit:CPSUAB, Unit_total:34571},
          {id:NeoPanamaxId, name: NeoPanamax, panamaxlocks:'NeoPanamax', description: NeoPanamax_description, Unit:CPSUAB, Unit_total:52212}];

        }
        else if(value == 'Quimiqueros'){
          let menor = "";
                            //this.ReplaceSegmentDatafunction(eslora, manga, addUnit, cpsuab, data, title="");   
          Regulars_description = this.ReplaceSegmentData("604", "90", "", "19,800", Regulars_description);
          Supers_description = this.ReplaceSegmentData("600", "106", "", "24,600", Supers_description);
          NeoPanamax_description = this.ReplaceSegmentData("600", "115", "", "27,700", NeoPanamax_description, menor);

          this.ListaBuques = [
          {id:RegularsId, name: Regulars, panamaxlocks:'Panamax', description: Regulars_description, Unit:CPSUAB, Unit_total:19769},
          {id:SupersId, name: Supers, panamaxlocks:'Panamax', description: Supers_description, Unit:CPSUAB, Unit_total:24633},
          {id:NeoPanamaxId, name: NeoPanamax, panamaxlocks:'NeoPanamax', description: NeoPanamax_description, Unit:CPSUAB, Unit_total:27726}];

        }
        else if(value == 'Portavehiculos'){
          let menor = "";
                            //this.ReplaceSegmentDatafunction(eslora, manga, addUnit, cpsuab, data, title="");   
          Regulars_description = this.ReplaceSegmentData("424", "71", "", "8,000", Regulars_description);
          Supers_description = this.ReplaceSegmentData("656", "106", "", "59,500", Supers_description);
          NeoPanamax_description = this.ReplaceSegmentData("662", "120", "", "76,500", NeoPanamax_description, menor);

          this.ListaBuques = [
          {id:RegularsId, name: Regulars, panamaxlocks:'Panamax', description: Regulars_description, Unit:CPSUAB, Unit_total:8056},
          {id:SupersId, name: Supers, panamaxlocks:'Panamax', description: Supers_description, Unit:CPSUAB, Unit_total:59436},
          {id:NeoPanamaxId, name: NeoPanamax, panamaxlocks:'NeoPanamax', description: NeoPanamax_description, Unit:CPSUAB, Unit_total:76429}];

        }
        else if(value == 'Pasajeros'){
                            //this.ReplaceSegmentDatafunction(eslora, manga, addUnit, cpsuab, data, title=""); 
          let menor = " < 10,000 " + CPSUAB;
          let mayor = " ≥ 10,000 " + CPSUAB;
   
          let RegularsMenor = this.ReplaceSegmentData("440", "52", "", "4,849", Regulars_description, menor);
          let RegularsMayor = this.ReplaceSegmentData("595", "85", "", "26,982", Regulars_description, mayor);
          Supers_description = this.ReplaceSegmentData("965", "106", "", "79,580", Supers_description);
          NeoPanamax_description = this.ReplaceSegmentData("1,094", "137", "", "141,948", NeoPanamax_description);

          this.ListaBuques = [
          {id:RegularsId+id_menor, name: Regulars+menor, panamaxlocks:'Panamax', description: RegularsMenor, Unit:CPSUAB, Unit_total:4489},
          {id:RegularsId+id_mayor, name: Regulars+mayor, panamaxlocks:'Panamax', description: RegularsMayor, Unit:CPSUAB, Unit_total:26982},
          {id:SupersId, name: Supers, panamaxlocks:'Panamax', description: Supers_description, Unit:CPSUAB, Unit_total:79580},
          {id:NeoPanamaxId, name: NeoPanamax, panamaxlocks:'NeoPanamax', description: NeoPanamax_description, Unit:CPSUAB, Unit_total:141948}];

        }
        else if(value == 'CargaGeneral'){
          let menor = " < 10,000 " + CPSUAB;
          let mayor = " ≥ 10,000 " + CPSUAB;
                            //this.ReplaceSegmentDatafunction(eslora, manga, addUnit, cpsuab, data, title="");   
          let RegularsMenor = this.ReplaceSegmentData("454", "70", "", "8,451", Regulars_description, menor);
          let RegularsMayor = this.ReplaceSegmentData("510", "77", "", "11,510", Regulars_description, mayor);
          Supers_description = this.ReplaceSegmentData("654", "100", "", "25,319", Supers_description);
          NeoPanamax_description = this.ReplaceSegmentData("656", "106", "", "34,486", NeoPanamax_description);

          this.ListaBuques = [
          {id:RegularsId+id_menor, name: Regulars+menor, panamaxlocks:'Panamax', description: RegularsMenor, Unit:CPSUAB, Unit_total:8451},
          {id:RegularsId+id_mayor, name: Regulars+mayor, panamaxlocks:'Panamax', description: RegularsMayor, Unit:CPSUAB, Unit_total:11510},
          {id:SupersId, name: Supers, panamaxlocks:'Panamax', description: Supers_description, Unit:CPSUAB, Unit_total:25319},
          {id:NeoPanamaxId, name: NeoPanamax, panamaxlocks:'NeoPanamax', description: NeoPanamax_description, Unit:CPSUAB, Unit_total:34486}];

        }
        else if(value == 'Refrigerados'){

          let menor = " < 10,000 " + CPSUAB;
          let mayor = " ≥ 10,000 " + CPSUAB;
                            //this.ReplaceSegmentDatafunction(eslora, manga, addUnit, cpsuab, data, title="");   
          let RegularsMenor = this.ReplaceSegmentData("492", "73", "", "8,316", Regulars_description, menor);
          let RegularsMayor = this.ReplaceSegmentData("557", "77", "", "12,455", Regulars_description, mayor);
          Supers_description = this.ReplaceSegmentData("623", "101", "", "18,710", Supers_description);

          this.ListaBuques = [
          {id:RegularsId+id_menor, name: Regulars+menor, panamaxlocks:'Panamax', description: RegularsMenor, Unit:CPSUAB, Unit_total:8316},
          {id:RegularsId+id_mayor, name: Regulars+mayor, panamaxlocks:'Panamax', description: RegularsMayor, Unit:CPSUAB, Unit_total:12455},
          {id:SupersId, name: Supers, panamaxlocks:'Panamax', description: Supers_description, Unit:CPSUAB, Unit_total:18710}];

        }
         else if(value == 'Otros'){

          let menor = " < 7,500 " + CPSUAB;
          let mayor = " ≥ 7,500 " + CPSUAB;
                            //this.ReplaceSegmentDatafunction(eslora, manga, addUnit, cpsuab, data, title="");   
          let RegularsMenor = this.ReplaceSegmentData("233", "43", "", "1,628", Regulars_description, menor);
          let RegularsMayor = this.ReplaceSegmentData("461", "79", "", "10,890", Regulars_description, mayor);
          Supers_description = this.ReplaceSegmentData("741", "107", "", "48,983", Supers_description);
          NeoPanamax_description = this.ReplaceSegmentData("764", "138", "", "33,284", NeoPanamax_description);

          this.ListaBuques = [
          {id:RegularsId+id_menor, name: Regulars+menor, panamaxlocks:'Panamax', description: RegularsMenor, Unit:CPSUAB, Unit_total:1628},
          {id:RegularsId+id_mayor, name: Regulars+mayor, panamaxlocks:'Panamax', description: RegularsMayor, Unit:CPSUAB, Unit_total:10890},
          {id:SupersId, name: Supers, panamaxlocks:'Panamax', description: Supers_description, Unit:CPSUAB, Unit_total:48983},
          {id:NeoPanamaxId, name: NeoPanamax, panamaxlocks:'NeoPanamax', description: NeoPanamax_description, Unit:CPSUAB, Unit_total:33284}];

        } 
        else if(value == 'Embarcacionesmenores'){
          this.ListaBuques = this.ListaLongitudEmbarcacion;
        }

        this.$refs.formServiciosRecurrentes.resetValidation();
      },
      Selectsize(value){
        this.TransitoReglasParte1("hasBooking");
        this.CapacidadBuqueTextFormat(value);
      },
      ChasportMovement(){
        this.TransitoReglasParte1("movement");

        this.TransitoReglasParte2('hasMoorageAnchorage');
        this.TransitoReglasParte2('hasPhotography');
        this.TransitoReglasParte2('hasBoarding');
        this.TransitoReglasParte2('hasSpecialPilotage');
        this.TransitoReglasParte1("hasBooking");
      },
      TCondition(){
        this.TransitoReglasParte1("loadedlTEU");
        this.TransitoReglasParte1("emptyTEU");
        this.formData.loadedlTEU.value = "";
        this.formData.emptyTEU.value = "";
      },
      TotalTEU(val){
        if(val>100){
          this.TeusError=true;
        }
        else{
          this.TeusError=false;
        }
      },
      CTypeMovement(){
        this.ChangeListServices();
        this.TransitoReglasParte1("condition");
        this.TransitoReglasParte1("hasPortMovement");
        this.TransitoReglasParte1("movement");
        this.TransitoReglasParte1("hasBooking");

        this.TransitoReglasParte2('hasMoorageAnchorage');
        this.TransitoReglasParte2('hasPhotography');
        this.TransitoReglasParte2('hasBoarding');
        this.TransitoReglasParte2('hasSpecialPilotage');
        this.TransitoReglasParte2('hasTransportation');

        this.ResetServiciosRecurrentes();
        this.ResetServiciosIncidentales();
        this.$refs.formServiciosRecurrentes.resetValidation();
        this.pagina = 1;
      },
      CDataFormServiciosRecurrentes(){
        this.ValidateForm();
      }
    },
    mounted(){
      this.ChangeListServices();
      this.TransitoReglasParte1("hasBooking");
      this.TransitoReglasParte1("movement");

      this.TransitoReglasParte2('hasMoorageAnchorage');
      this.TransitoReglasParte2('hasPhotography');
      this.TransitoReglasParte2('hasBoarding');
      this.TransitoReglasParte2('hasSpecialPilotage');
      this.TransitoReglasParte2('hasTransportation');
    },
  methods:{
    CapacidadBuqueTextFormat: function(value){
      this.formData.loadedlTEU.value = "";
      this.formData.emptyTEU.value = "";
      let vessel = this.ListaBuques.find(x => x.id == value);

      if(!vessel)
      {
        return false;
      }

      this.ListaCargaPorcentajes = this.ListaCargaPorcentajes.map(item =>
      vessel.Unit!="" && vessel.Unit_total >0 ?
       { ...item, id: item.id, name: item.id + "% " + " (" + this.currencyUsd(Number(item.id)*Number(vessel.Unit_total)/100, 0) + " " + vessel.Unit + ")" }
        : { ...item } 
      );
    },
    ReplaceSegmentData: function(eslora, manga, addUnit, cpsuab, data, title=""){
      return data.replaceAll("%eslora%", eslora).replaceAll("%manga%", manga).replaceAll("%AddUnit%", addUnit).replaceAll("%cp/suab%", cpsuab).replaceAll("%addTitle%", title);
    },
    FormalizeAction: function(){
      this.modalFormalizar = false;
      window.open(this.URL_FORMALIZAR,'_blank');
    },
    irPagina: function(NuevaPagina){
      if(NuevaPagina == 1){
        this.ResetServiciosIncidentales();
      }
      window.scrollTo(0, 0);
      this.pagina = NuevaPagina;
    },
    ResetServiciosRecurrentes: function(){
      this.formData.segment.value = "";
      this.formData.size.value = "";
      this.formData.condition.value = "";
      this.formData.loadedlTEU.value = "";
      this.formData.emptyTEU.value = "";
      this.formData.hasBooking.value = "false";
      this.formData.hasPortMovement.value = "false";
      this.formData.movement.value = "";
    },
    ResetServiciosIncidentales: function(){
      this.formData.hasMoorageAnchorage.value = "false";
      this.formData.hasPhotography.value = "false";
      this.formData.hasBoarding.value = "false";
      this.formData.hasSpecialPilotage.value  = "false";
      this.formData.hasTransportation.value  = "false";
    },
    ChangeListServices: function(){
      if(this.TypeMovement==0){
          this.detalles = {
          estimateTotal: "-.--",
          ListaServiciosRecurrentes:[
          {ico:'calendar', nombre:  i18n.t('ListaServiciosRecurrentes.resultPeaje'), campo:'resultTotalToll', valor: '-.--', visible: false },
          {ico:'hands', nombre:  i18n.t('ListaServiciosRecurrentes.resultTarifas'), campo:'resultTotalRecurrent', valor: "-.--", visible: false }
          ],
          ListaServiciosIncindentales:[
            {ico:'calendar', nombre: i18n.t('ListaServiciosIncindentales.resultMoorageAnchorage'), campo:'resultMoorageAnchorage', valor: "-.--", visible: false},
            {ico:'hands', nombre: i18n.t('ListaServiciosIncindentales.resultPhotography'), campo:'resultPhotography', valor: "-.--", visible: false},
            {ico:'hands', nombre: i18n.t('ListaServiciosIncindentales.resultBoarding'), campo:'resultBoarding', valor: "-.--", visible: false},
            {ico:'hands', nombre: i18n.t('ListaServiciosIncindentales.resultSpecialPilotage'), campo:'resultSpecialPilotage', valor: "-.--", visible: false},
            {ico:'hands', nombre: i18n.t('ListaServiciosIncindentales.resultTransportation'), campo:'resultTransportation', valor: "-.--", visible: false}
          ],
          ListaServiciosMovimientosPuertarios:[
          {ico:'calendar', nombre:  i18n.t('ListaServiciosRecurrentes.resultPilotage'), campo:'resultPilotage', valor: "-.--", visible: false },
          {ico:'hands', nombre:  i18n.t('ListaServiciosRecurrentes.resultchannelfee'), campo:'resultChannelFee', valor: "-.--", visible: false }
          ]
        };
        if(this.ListaSegmentos.find((item) => item.id === 'Embarcacionesmenores') == undefined){
          this.ListaSegmentos.push({id:'Embarcacionesmenores', name: i18n.t('ListaSegmentos.ASEM')});
        }
      }
      else{
          this.detalles = {
          estimateTotal: "-.--",
          ListaServiciosRecurrentes:[
          {ico:'calendar', nombre:  i18n.t('ListaServiciosRecurrentes.resultPeaje'), campo:'resultTotalToll', valor: '-.--', visible: false },

          /****** tab movimientos portuarios ******/
          {ico:'calendar', nombre:  i18n.t('ListaServiciosRecurrentes.resultPilotage'), campo:'resultPilotage', valor: "-.--", visible: false },
          {ico:'hands', nombre:  i18n.t('ListaServiciosRecurrentes.resultchannelfee'), campo:'resultChannelFee', valor: "-.--", visible: false }
          ],
          ListaServiciosIncindentales:[
          {ico:'calendar', nombre:  i18n.t('ListaServiciosIncindentales.resultSpecialPilotage'), campo:'resultSpecialPilotage', valor: '-.--', visible: false },
          {ico:'hands', nombre: i18n.t('ListaServiciosIncindentales.resultTransportation'), campo:'resultTransportation', valor: "-.--", visible: false}
          ],
          ListaServiciosMovimientosPuertarios:[]
        };
        if(this.ListaSegmentos.find((item) => item.id === 'Embarcacionesmenores') != undefined){
          this.ListaSegmentos = this.ListaSegmentos.filter(item => item.id !== 'Embarcacionesmenores');
        }
      }
    },
    ExportDocumentPdf: async function(){

      this.isLoading=true;
      const fileName = i18n.t('pdf.Filename')+moment().format('DD[_]MMM[_]YYYY[_]hh[_]mm').toString(); 

      const contentHtml = this.GetHtmlAndCss();
      var opt = {
        margin:       0,
        filename:     fileName + '.pdf',
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 3 },
        jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' },
        pagebreak:    { before: '.Pagebreak' }
      };

      html2pdf().set(opt).from(contentHtml).save();
      this.isLoading = false;
    },
    GetHtmlAndCss(){
        this.IsPrinting = true;
        let html = '<!DOCTYPE html><html lang="en">' +
        "<head>" + document.getElementsByTagName('head')[0].innerHTML +  
        "</head>" +
        "<body>" +  document.getElementsByClassName("DocumentPdf")[0].getHTML() +  
          "</body>" +
        "</html>";
        return html;
      },
      RemoveElements: function(content){
        let ElementsByClassForDelete = ['v-dialog__content', 'v-overlay'];
        ElementsByClassForDelete.forEach((item) => {
          // console.log(item);
          // console.log(document.getElementsByClassName(item));
          if(document.getElementsByClassName(item).length>0){
            document.getElementsByClassName(item).forEach((key) => {
              content = content.replaceAll(key.getHTML(), "");
            });
          }
        });
        return content;
      },
    TransitoReglasParte1: function(control){
      let result = false;
      /* Reglas de transito o movimiento puertario */
      
      if(this.TypeMovement == "0")
      {
        /* Reglas de tipo de segmento */
        if(this.formData.segment.value=='Contenedores' && this.formData.condition.value == 'Laden')
        {
          if((control=="loadedlTEU"||control=="emptyTEU"))
          {
            result = true;
          }
        }
        else if(this.formData.segment.value=='Pasajeros')
        {
          if(control=="condition"){
            result = true;
          }
        }
        else if(this.formData.segment.value=='Embarcacionesmenores'){
          if(control=="size" || ( control=="hasBooking" && this.formData.size.value == "4" )){
            result = true;
          }
          else if(control=="condition"){
            result = false;
          }
          this.movimientoPortuarioChanged(false)
        }

        if((control=="condition" && this.formData.segment.value != 'Embarcacionesmenores') || (control=="hasPortMovement" && (this.formData.segment.value != 'Embarcacionesmenores')) || (control=="hasBooking" && this.formData.segment.value!='Embarcacionesmenores') || control=="size" || (control=="movement" && this.formData.hasPortMovement.value=='true')){
            result = true;
          }
      }
      else if(this.TypeMovement == "1"){
          if(control=="size"){
            result = true;
          }
        }
      
      if(this.formData[control]){
        this.formData[control].visible = result;
      }

      return result;
    },
    TransitoReglasParte2: function(control){
      let result = false;
      /* control para campos de Servicios incidentales */
      if(control=="hasMoorageAnchorage"){
        if(this.TypeMovement != "0"){
          result = false;
        }
        else if(this.formData.segment.value == "Contenedores" || this.formData.segment.value == "Pasajero" || this.formData.segment.value == "Embarcacionesmenores" || this.formData.hasPortMovement.value!=""){
          result = true;
        }
        else{
          result = false;
        }
      }
      else if(control=="hasPhotography"){
        if(this.TypeMovement != "0"){
          result = false;
        }
        else if(this.formData.segment.value == "Contenedores" || this.formData.segment.value == "Pasajeros" || this.formData.segment.value == "Embarcacionesmenores" || this.formData.hasPortMovement.value!=""){
          result = true;
        }
      }
      else if(control=="hasBoarding"){
        if(this.TypeMovement != "0"){
          result = false;
        }
        else if(this.formData.segment.value == "Embarcacionesmenores" || this.formData.segment.value == "Pasajeros" || this.formData.segment.value == "Otros"){
          result = true;
        }
      }
      else if(control=="hasSpecialPilotage"){
        if(this.formData.hasPortMovement.value=="true" || this.TypeMovement == "1"){
          result = true;
        }
        else{
          result = false;
        }
      }
      else if(control=="hasTransportation"){
        if(this.TypeMovement == "0"){
          result = true;
        }
      }

      if(this.formData[control]){
        this.formData[control].visible = result;
      }

      return result;
    },
    ReservacionChanged: function (item){
        this.formData.hasBooking.value = item;
      },
    movimientoPortuarioChanged: function (item){
        this.formData.hasPortMovement.value = item;
      },
      fondeoChanged: function (item){
        this.formData.hasMoorageAnchorage.value = item;
      },
      fotografiaChanged: function (item){
        this.formData.hasPhotography.value = item;
      },
      EmbarqueChanged: function (item){
        this.formData.hasBoarding.value = item;
      },
      PracticajeChanged: function (item){
        this.formData.hasSpecialPilotage.value = item;
      },
      TrasportationChanged: function(item){
        this.formData.hasTransportation.value = item;
      },
      ValidateForm: async function(page){
        let SendData = this.FormatDataToApi(this.formData);
        this.$refs.formServiciosRecurrentes.validate();
        let isValid = !this.ExistEmpyField(SendData);

        this.showformWarning = !isValid && page; 
        if(page && isValid){
          this.irPagina(page);
        }
        else if(!page && isValid && this.TotalTEU <= 100){
          this.isLoading = true;
          try {
            // console.log(JSON.stringify(SendData));
            var resp = await this.CalculatedFess(SendData);
            // console.log(JSON.stringify(resp));
            this.FormatData(resp);
          } catch (error) {
            console.error(error);
            this.showformError = true;
          }
          this.isLoading = false;
       }
       else if(!page){
        this.estimate = "-.--";
        this.detalles.estimateTotal = "-.--";
        this.$refs.formServiciosRecurrentes.resetValidation();
       }

      },
      ExistEmpyField(data){
        for (let clave in data){
          if(data[clave] == ""){
            return true;
          }
        }
        return false;
      },
      FormatDataToApi: function(data){
        // console.log(data);
        let res = {};
        for (let clave in data){
          if(data[clave].visible){
            res[clave] = data[clave].value;
          }
        }
        return res;
      },
      CalculatedFess: async function(DataBody){
        let controller = "transito";

        if(this.TypeMovement != "0"){
          controller = "movimiento";
        }
        
        // console.log(JSON.stringify(DataBody));
        const response = await fetch("/api/"+controller+"?code="+process.env.VUE_APP_FUNCTION_KEY,
        {method: "POST",
        body: JSON.stringify(DataBody)});
        return await response.json();
      },
      DataToJson: function(){

      },
      FormatData: function(DataJson){
        this.ChangeListServices();

        if(!DataJson || DataJson.length < 0){
          return false;}
        if(Object.keys(DataJson).length === 0){
          return false;}
        
        let data = DataJson.calculatedFees;
        // console.log("data");
        // console.log(data);
        if(!data || Object.keys(data).length === 0){
          return false}

        var listas = this.ListaSegmentos.concat(this.ListaBuques, this.ListaCondicion, this.ListaMovimientoPortuario, this.ListaRutas, this.ListaCargaPorcentajes, this.ListaLongitudEmbarcacion); 

        if(data.estimate){
          this.estimate = this.currencyUsd(data.estimate);
          this.detalles.estimateTotal = this.currencyUsd(data.estimate);
        }
        else{
          this.estimate = "-.--";
          this.detalles.estimateTotal = "-.--";
        }

        this.detalles.ListaServiciosRecurrentes = this.detalles.ListaServiciosRecurrentes.map(p=>
          data?.[p.campo] !== undefined && data?.[p.campo] ?
          { ...p, nombre: this.replaceData(listas, p.nombre, data, p.decimals), visible: true, valor: this.currencyUsd(data?.[p.campo]) }
          : { ...p, nombre: this.replaceData(listas, p.nombre, data), visible: false, valor: '-.--' }
        );

        this.detalles.ListaServiciosIncindentales = this.detalles.ListaServiciosIncindentales.map(p=>
          data?.[p.campo] !== undefined && data?.[p.campo] ?
          { ...p, nombre: this.replaceData(listas, p.nombre, data, p.decimals), visible: true, valor: this.currencyUsd(data?.[p.campo]) }
          : { ...p, nombre: this.replaceData(listas, p.nombre, data), visible: false, valor: '-.--' }
        );

        this.detalles.ListaServiciosMovimientosPuertarios = this.detalles.ListaServiciosMovimientosPuertarios.map(p=>
          data?.[p.campo] !== undefined && data?.[p.campo] ?
          { ...p, nombre: this.replaceData(listas, p.nombre, data, p.decimals), visible: true, valor: this.currencyUsd(data?.[p.campo]) }
          : { ...p, nombre: this.replaceData(listas, p.nombre, data), visible: false, valor: '-.--' }
        );
      },
      defaultDetallesRevision(){
        this.detalles.ListaServiciosRecurrentes = this.detalles.ListaServiciosRecurrentes.map(p=>
         ({ ...p, visible: false, valor: '-.--' })
        );

        this.detalles.ListaServiciosIncindentales = this.detalles.ListaServiciosIncindentales.map(p=>
          ({ ...p, visible: false, valor: '-.--' })
        );

        this.detalles.ListaServiciosMovimientosPuertarios = this.detalles.ListaServiciosMovimientosPuertarios.map(p=>
          ({ ...p, visible: false, valor: '-.--' })
        );

        this.estimate = "-.--";
        this.detalles.estimateTotal = "-.--";
      },
      replaceData(listas, data, calculatedFees, decimals = null){
        var d = 0;
        if(decimals!=null){
          d=decimals;
        }

        for (let clave in this.formData){
          if(data.includes("%"+clave+"%")){
            let item = listas.find((x) => x.id === this.formData[clave].value);
            if(item){
              data = data.replaceAll("%"+clave+"%", item.name);
            }
          }
        }

        for(let clave in calculatedFees){
          if(data.includes("&"+clave+"&")){
            if(calculatedFees[clave]!= undefined)
            {
              data = data.replaceAll("&"+clave+"&", this.currencyUsd(calculatedFees[clave], d));
            }
          }
        }

        if(data.includes("#panamaxlocks#")){
          let item2 = listas.find((x) => x.id === this.formData.size.value);
          if(item2){
            data = data.replaceAll("#panamaxlocks#", item2.panamaxlocks);
          }
        }

        return data;
      },
      currencyUsd(value, decimals = 2){
        if(!value && !isNaN(value) && value < 0){
          return "-.--";
        }

        let result = Number(value.toString()).toFixed(decimals);
        let m = Number(result.split(".")[0]).toLocaleString();
        let d = result.split(".")[1];

        if(m)
          result=m;
        if(d)
          result+="."+d;

        return result;
      },
      getUrlImg(img){
        return require("@/assets/images/" + img + ".svg");
      }
  }
  }
</script>
