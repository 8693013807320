import Vue from 'vue'
import VueRouter from 'vue-router'
import TransitoView from '../views/TransitoView.vue'
import i18n from '../i18n'

Vue.use(VueRouter)

const routes = [
 {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:lang',
    component:{
      render (c) { return c('router-view')}
    },
    children:[
      {
        path: '/',
        name: 'transito',
        component: TransitoView
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
