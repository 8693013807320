<template>
    <v-flex style="width: 100%;" v-if="show=='true' && ListItems.length>0">
        <v-flex xs12 md12 no-gutters >
            <label class="acp-label-bold">{{$t(title)}}</label>
        </v-flex>
        <v-flex class="acp-separador-sub">
        </v-flex>
        <!--  -->
        <v-flex v-if="isLoading" mb-3 xs12 md12 class="d-flex align-start justify-space-between" no-gutters>
            <span class="acp-spinner"></span>
        </v-flex>
        <v-flex xs12 md12 v-if="!isLoading">
            <v-flex  v-for="item in ListItems" :key="item.campo" class="d-flex align-start justify-space-between" no-gutters> 
            <v-flex v-if="item.visible" no-gutters class="mb-3"> 
                <v-img :src="getUrlImg(item.ico)" class="shrink mr-2 align-end" contain width="25px"></v-img>
            </v-flex>
            <label v-if="item.visible" class="acp-label-grey acp-label-detalles mb-3">{{item.nombre}}</label>
            <label v-if="item.visible" class="acp-span-money-blue mb-3">${{item.valor}}</label>
            </v-flex>
        </v-flex>
        <!---->
    </v-flex> 
</template>
<script>
    export default {
      props:["title",'isLoading', "ListItems", "show"],
      name: 'DetailsSection',
      methods:{
        getUrlImg(img){
            return require("@/assets/images/" + img + ".svg");
        }
      }
    }
</script>