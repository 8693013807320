<template>
    <div>
        <div class="home_banner">
          <v-container no-gutters class="acp-container">
            <v-row class="acp-contariner-block" no-gutters>
              <v-col cols="12" class="d-flex acp-maxheight">
                <v-flex class="align-self-center justify-end acp-container-img">
                  <v-img src="../assets/images/calc.svg" class="shrink" contain ></v-img>
                </v-flex>
                <v-flex class="align-self-center" style="height: 50%;">
                  <h3 class="main-text">{{ $t('transito.main-text') }}</h3>
                  <span class="main-span-text">{{ $t('transito.main-span-text') }}</span>
                </v-flex>
              </v-col>
            </v-row>
            <v-row class="acp-contariner-blockSub" no-gutters>
              <v-col cols="12" align-self="end" style="height: 75px;">
                <v-tabs
                  class="acp-main-tab"
                  background-color="transparent"
                  v-model="tab"
                  hide-slider>
                      <v-tab key="0" class="acp-item-tab acp-item-tab-first acp-item-tab-1" :class="tab == 1 ? 'acp-item-tab-select' : ''">
                        <v-flex row no-gutters>
                          <v-img v-if="tab == 1" src="../assets/images/transito-off.svg" class="shrink mr-2 align-end" contain width="32px"></v-img>
                          <v-img v-if="tab == 0" src="../assets/images/transito-on.svg" class="shrink mr-2 align-end" contain width="32px"></v-img>
                          <span class="acp-item-span">{{ $t('transito.tab1') }}</span>
                        </v-flex>
                      </v-tab>
                      <v-tab key="1" class="acp-item-tab acp-item-tab-last acp-item-tab-2" :class="tab == 0 ? 'acp-item-tab-select' : ''">
                        <v-flex row no-gutters>
                          <v-img v-if="tab == 1" src="../assets/images/movimiento-on.svg" class="shrink mr-2 align-end" contain width="32px"></v-img>
                          <v-img v-if="tab == 0" src="../assets/images/movimiento-off.svg" class="shrink mr-2 align-end" contain width="32px"></v-img>
                          <span class="acp-item-span" v-html="$t('transito.tab2')" ></span>
                        </v-flex>
                      </v-tab>
                </v-tabs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      <Calculadora :TypeMovement="tab"/>
    </div>
</template>
<script>
import Calculadora from '../components/Calculadora';
export default {
  name: 'TransitoView',
  components: {
    Calculadora
},
  data: () => ({
    tab: 0,
  }),
};
</script>